import Head from 'next/head';

import {
  abeagle_host,
  destinations,
  image_service_url,
  recsys_api_origin,
} from '../../../constants';

import { isServer } from '../../../utils/isServer';
import { getUserGeo } from '../../../utils/localization';

function isGTMEnabled(destinations, buzz) {
  if (isServer()) {
    return false;
  }

  const userGeo = getUserGeo();
  return destinations[buzz.destination_name].gtm_enabled && userGeo != 'US';
}

/**
 * preconnect:
 *   Only preconnect to critical domains you will use soon because the browser closes any
 *   connection that isn't used within 10 seconds. Unnecessary preconnecting can delay other
 *   important resources, so limit the number of preconnected domains and test the impact
 *   preconnecting may have on metrics such as Largest Contentful Paint and Time To Interative.
 * dns-prefetch:
 *   If a page needs to make connections to many third-party domains, preconnecting all of them is
 *   counterproductive as explained above. For all the rest, use <link rel=dns-prefetch> to save
 *   time on the first step, the DNS lookup, which usually takes around 20–120 ms. This resource
 *   hint can also serve as a fallback. Support for dns-prefetch is better than support for
 *   preconnect. Browsers that don’t support preconnect will still get some added benefit by
 *   falling back to dns-prefetch.
 */
export default function PreconnectDnsPrefetch({ buzz }) {
  const isJapan = buzz.classification.edition === 'Japan';
  return (
    <>
      <Head>
        {/* Permutive */}
        <link rel="preconnect" href="https://cdn.permutive.com" />
        {/* image CDN */}
        <link rel="preconnect" href={image_service_url} />
        <link rel="dns-prefetch" href={image_service_url} />
        {/* ABeagle tracking */}
        <link rel="preconnect" href={abeagle_host} crossOrigin="" />
        <link rel="dns-prefetch" href={abeagle_host} />
        {/* apstag */}
        <link rel="dns-prefetch" href="https://c.amazon-adsystem.com" />
        <link rel="preconnect" href="https://c.amazon-adsystem.com" crossOrigin="" />
        <link rel="dns-prefetch" href="https://c.aps.amazon-adsystem.com" />
        <link rel="preconnect" href="https://c.aps.amazon-adsystem.com" crossOrigin="" />
        {/* Google Tag Manager */}
        {isGTMEnabled(destinations, buzz) && (
          <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
        )}
        {/* Ads */}
        {!buzz.disableAds && (
          <>
            <link rel="dns-prefetch" href="https://c.amazon-adsystem.com" />
            <link rel="dns-prefetch" href="https://tagan.adlightning.com" />
          </>
        )}
        {/* Connatix */}
        {!isJapan &&
          <link rel="dns-prefetch" href="https://cd.connatix.com" />
        }
        {!isJapan &&
          <link rel="dns-prefetch" href="https://cds.connatix.com" />
        }
        {/* Permutive */}
        <link rel="dns-prefetch" href="https://cdn.permutive.com" />
        {/* TypeKit */}
        <link rel="dns-prefetch" href="https://use.typekit.net" />
        <link rel="dns-prefetch" href="https://p.typekit.net" />
        {/* Recsys */}
        <link rel="dns-prefetch" href={recsys_api_origin} />
        {/* Sentry */}
        <link rel="dns-prefetch" href="https://sentry.io" />
        {/* Captcha */}
        <link rel="dns-prefetch" href="https://www.google.com" />
        <link rel="dns-prefetch" href="https://www.gstatic.com" />
        {/* Pinterest */}
        <link rel="dns-prefetch" href="https://s.pinimg.com" />
        {/* Nielson */}
        <link rel="dns-prefetch" href="https://cdn-gl.imrworldwide.com" />
        {/* ComScore */}
        <link rel="dns-prefetch" href="https://sb.scorecardresearch.com" />
        {/* Outbrain */}
        <link rel="dns-prefetch" href="https://amplify.outbrain.com" />
        <link rel="dns-prefetch" href="https://tr.outbrain.com" />
        {/* Trackanomics */}
        <link rel="dns-prefetch" href="https://cdn-magiclinks.trackonomics.net" />
        <link rel="dns-prefetch" href="https://js.stripe.com" />
      </Head>
    </>
  );
}
